<!--
 * @Description: 新增、修改人员
 * @Author: 琢磨先生
 * @Date: 2022-05-20 11:24:29
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2023-04-06 15:49:49
-->

<template>
  <el-dialog
    v-model="visible"
    :title="title"
    top="10vh"
    width="750px"
    draggable
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @closed="onClosed"
  >
    <el-form :model="form" ref="form" :rules="rules" label-width="140px">
      <el-tabs>
        <el-tab-pane label="用户资料">
          <el-form-item label="部门" prop="deptIds">
            <el-col :span="18" :offset="0">
              <el-cascader
                style="width: 100%"
                clearable
                :options="depts"
                v-model="form.deptIds"
                :props="{ label: 'name', value: 'id', checkStrictly: true }"
                @change="deptChange"
              ></el-cascader>
            </el-col>
          </el-form-item>
          <el-form-item label="职位" prop="job_id">
            <el-select v-model="form.job_id" placeholder="选择职位" clearable>
              <el-option :label="item.name" :value="item.id" :key="item.id" v-for="item in jobList"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="姓名" prop="chs_name">
            <el-col :span="14">
              <el-input v-model="form.chs_name" placeholder="请输入姓名"></el-input>
            </el-col>
          </el-form-item>
          <el-form-item label="手机号码" prop="mobile_phone">
            <el-col :span="14">
              <el-input v-model="form.mobile_phone" placeholder="请输入手机号码"></el-input>
            </el-col>
            <div class="help-block">手机号可作为账号登录</div>
          </el-form-item>
          <el-form-item label="性别" prop="sex">
            <el-radio-group v-model="form.sex">
              <el-radio :label="1">男</el-radio>
              <el-radio :label="2">女</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="登录账号" prop="user_name">
            <el-col :span="14">
              <el-input v-model="form.user_name" placeholder="账号"></el-input>
            </el-col>
            <div class="help-block">支持字母、数字、_、中文</div>
          </el-form-item>

          <el-form-item label="登录密码" prop="password">
            <el-col :span="14">
              <el-input v-model="form.password" placeholder="密码"></el-input>
            </el-col>
          </el-form-item>

          <el-form-item label="状态" prop="status">
            <el-radio-group v-model="form.status">
              <el-radio
                :label="item.value"
                v-for="item in statusList"
                :key="item.value"
              >{{ item.text }}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="部门主管" prop="status">
            <el-switch v-model="form.is_master"></el-switch>
            <div class="help-block">部门主管是部门的直接领导</div>
          </el-form-item>
          <el-form-item label="岗位角色">
            <el-checkbox-group v-model="form.postIds">
              <el-checkbox :label="item.id" v-for="item in posts" :key="item.id">
                {{
                item.name
                }}
              </el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </el-tab-pane>
        <el-tab-pane label="数据权限">
          <el-tree
            ref="tree"
            :data="prm_data_list"
            node-key="code"
            :props="{id:'code', label:'name'}"
            default-expand-all
            show-checkbox
          ></el-tree>
        </el-tab-pane>
      </el-tabs>
    </el-form>

    <template #footer>
      <el-button type="primary" @click="onSubmit" :loading="saving" :disabled="saving">确定</el-button>
      <!-- <el-button @click="cancelClick" :disabled="saving">取消</el-button> -->
    </template>
  </el-dialog>
</template>

<script>
import job_api from "@/http/job_api";
import common_api from "@/http/common_api";

export default {
  data() {
    return {
      loading: false,
      saving: false,
      visible: false,
      title: "",
      powers: [],
      form: {},
      deptList: [],
      jobList: [],
      prm_data_list: [],
      rules: {
        chs_name: [
          {
            required: true,
            message: "请输入名称",
            trigger: "blur",
          },
        ],
        // deptIds: [
        //   {
        //     required: true,
        //     message: "部门必选",
        //     trigger: "blur",
        //   },
        // ],
        mobile_phone: [
          {
            required: true,
            message: "请输入手机号码",
            trigger: "blur",
          },
          {
            pattern: /^1[23456789]\d{9}$/,
            message: "手机号码格式错误",
            trigger: "blur",
          },
        ],
        password: [
          {
            min: 6,
            message: "密码长度不少于6为字符",
            trigger: "blur",
          },
        ],
      },
    };
  },
  props: ["item", "depts", "posts", "statusList"],
  emits: ["close", "success"],
  watch: {
    item: {
      handler() {
        if (this.item) {
          this.form = {
            sex: 1,
            status: this.statusList[0].value,
          };
          this.visible = true;
          if (this.item.id) {
            this.saving = false;
            this.form = Object.assign({}, this.item);
            if (!this.form.postIds) {
              this.form.postIds = [];
            }
            this.title = "修改人员";
            if (this.item.dept_id) {
              this.loadJob();
            }
            this.$nextTick(()=>{
            // console.log(this.$refs.tree);
            this.$refs.tree.setCheckedKeys(this.item.prm_data_codes);
            });
          } else {
            this.title = "新增人员";
          }
        }
      },
      immediate: true,
    },
  },
  created() {
    //
    common_api.get_permission_data().then((res) => {
      if (res.code == 0) {
        this.prm_data_list = res.data;
      }
    });
  },
  methods: {
    /**
     * 修改时默认选中
     */
    defaultCheck(item) {
      if (this.dtl.powerIds.indexOf(item.id) > -1) {
        this.$refs.table.toggleRowSelection(item, true);
      }
      if (item.children.length > 0) {
        item.children.forEach((child) => {
          this.defaultCheck(child);
        });
      }
    },

    /**
     * 提交保存
     */
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.form.deptIds && this.form.deptIds.length > 0) {
            this.form.dept_id = this.form.deptIds[this.form.deptIds.length - 1];
          }

          var nodes = this.$refs.tree.getCheckedNodes();
          this.form.prm_data_codes=[];
          if(nodes.length>0){
              this.form.prm_data_codes = nodes.map(x=>x.code);
          }
          // console.log(this.form);
          this.saving = true;
          this.$http
            .post("seller/v1/emp/edit", this.form)
            .then((res) => {
              this.saving = false;
              if (res.code == 0) {
                this.visible = false;
                this.$message({
                  type: "success",
                  message: res.msg,
                }); 
                this.$emit("success");
              }
            })
            .cath(() => {
              this.saving = false;
            });
        }
      });
    },
    // /**
    //  * 取消
    //  */
    // cancelClick() {
    //   this.visible = false;
    //   if (this.reload) {
    //     this.reload();
    //   }
    // },
    /**
     * 部门选择更改
     */
    deptChange(values) {
      var deptId = values[values.length - 1];
      this.form.dept_id = deptId;
      this.form.job_id = null;
      this.loadJob();
    },
    /**
     * 加载职位
     */
    loadJob() {
      job_api.get_select(this.form.dept_id).then((res) => {
        if (res.code == 0) {
          this.jobList = res.data;
        }
      });
    },
    onClosed() {
      this.$emit("close");
    },
  },
};
</script>

<style   scoped>
</style>