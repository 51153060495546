/*
 * @Description:
 * @Author: 琢磨先生
 * @Date: 2022-05-23 01:30:27
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-05-23 01:32:15
 */

import http from "./axios_init.js";

export default {
  /**
   * 获取部门的职位
   * @returns
   */
  get_jobs(deptId) {
    return http.post("seller/v1/job?deptId=" + deptId);
  },

  /**
   * 职位下拉框加载数据
   * @param {*} deptId 
   * @returns 
   */
  get_select(deptId) {
    return http.post("seller/v1/job/select?deptId=" + deptId);
  },

  
};
